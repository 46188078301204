html {
  font-size: 10px;
}

body {
  font-size: 1.4em;
}

.MuiButtonBase-root.MuiButton-root {
  text-transform: initial;
}

.MuiPopover-paper a {
  color: var(--ion-text-color);
}
